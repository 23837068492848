import React, { useEffect, useState } from "react";

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import ArrowRightAltIcon from "@material-ui/icons/ArrowRightAlt";
import clsx from "clsx";
import { navigate } from "gatsby";
import moment from "moment";

import Appointment from "~/utils/interfaces/Appointment";

moment.locale("es");

interface DashboardGridProps {
  appointments: Appointment[];
  totalRows: number;
  currentPage: number;
  handleChangePage: (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => void;
}

const DashboardGrid = (props: DashboardGridProps) => {
  const [appointments, setAppoinments] = useState<Appointment[]>([]);

  const displayTableRows = (): Array<JSX.Element> => {
    return appointments.map((appointment: Appointment) => {
      const patientRut = appointment.patient?.document_number;
      return (
        <TableRow key={appointment.id}>
          <TableCell
            className={clsx(
              "relative hover:bg-gray-200 cursor-pointer",
              appointment.time_conflict &&
                'after:content-["Conflicto_de_Horario"] after:absolute after:top-0 after:right-0 after:h-full after:w-10 after:bg-red-500',
            )}
            onClick={async () => navigate(`/medical-orders/${appointment.id}/`)}
          >
            <p className="font-bold">{appointment.patient?.fullname || "Sin paciente asignado"}</p>
            <span className="font-bold">
              {patientRut} <ArrowRightAltIcon fontSize="small" />
              {moment(appointment.local_begin_date).format("LLL")}
            </span>
            <div>{appointment.target_address}</div>
            <div>{appointment.service_names}</div>
          </TableCell>

          <TableCell align="center">{appointment.block_taken ? "Sí" : "No"}</TableCell>
          <TableCell
            align="center"
            color={appointment.status === "scheduled" ? "incomplete" : "completed"}
          >
            {appointment.status === "scheduled" ? "No" : "Sí"}
          </TableCell>
          <TableCell
            align="center"
            className={clsx(appointment.finished && "bg-[#039BE5]", !appointment.finished && "bg-[#DA627D]")}
          >
            {appointment.finished ? "Sí" : "No"}
          </TableCell>
        </TableRow>
      );
    });
  };

  useEffect(() => {
    setAppoinments(props.appointments);
  }, [props.appointments]);

  return (
    <div className="mx-24 my-0 mb-24">
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>
                <div className="italic">Appointment</div>
              </TableCell>
              <TableCell align="center">Horario tomado</TableCell>
              <TableCell align="center">Confirmado</TableCell>
              <TableCell align="center">
                <div className="italic">Finished</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{displayTableRows()}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={props.totalRows}
                rowsPerPage={20}
                rowsPerPageOptions={[20]}
                page={props.currentPage}
                onPageChange={props.handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DashboardGrid;
