import React, { useContext, useEffect, useState } from "react";

import MomentUtils from "@date-io/moment";
import { Button, FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { MenuProps as MenuPropsType } from "@material-ui/core/Menu";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { isEmpty } from "lodash";
import * as moment from "moment";
import { StringParam, useQueryParam } from "use-query-params";

import MedicalOrderGrid from "~/components/Appointments/MedicalOrderGrid";
import UploadMedicalOrderRequest from "~/components/Appointments/UploadMedicalOrderRequest";
import PrivateRoute from "~/components/Authentication/PrivateRoute";
import LoadingError from "~/components/Loaders/LoadingError";
import { FiltersContext } from "~/components/Stores/FilterStore";
import api from "~/utils/api/api";
import appointmentService from "~/utils/api/v1/appointmentService";
import * as ciudadColonia from "~/utils/comunas/ciudad_colonia.json";
import * as regionComuna from "~/utils/comunas/region_comunas.json";
import { translateRegion } from "~/utils/comunas/regionTranslator";
import { COUNTRIES } from "~/utils/data/constants";
import Appointment from "~/utils/interfaces/Appointment";

moment.locale("es");

interface AppointmentRequest {
  data: {
    count: number;
    next: string | null;
    previous: string | null;
    results: Appointment[];
  };
}

const locationSelectProps: Partial<MenuPropsType> = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  getContentAnchorEl: null,
};

const IndexPage = (props): JSX.Element => {
  const [error, setError] = useState<Object>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [appointments, setAppointments] = useState<Array<Appointment>>([]);
  // @ts-expect-error
  const [filters, filtersDispatch] = useContext(FiltersContext);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [nextRequest, setNextRequest] = useState<string | null>(null);
  const [prevRequest, setPrevRequest] = useState<string | null>(null);
  const [search, setSearch] = useQueryParam("search", StringParam);

  const statusMap = {
    "Sin confirmar": "scheduled",
    Confirmado: "confirmed",
  };

  const fetchAppointments = async (): Promise<void> => {
    setLoading(true);
    try {
      const request: AppointmentRequest = await api.request({
        method: "get",
        url: "dashboard/appointments/receipts_list/",
        params: {
          begin_date: filters.initDate.format("YYYY-MM-DD"),
          end_date: filters.finishDate.format("YYYY-MM-DD"),
          nurse: filters.nurse,
          booked: filters.booked,
          contacted: filters.contacted,
          visited: filters.visited,
          "receipt-sent": filters.receipt,
          "results-sent": filters.results,
          sales_source: filters.salesSource,
          country: filters.country,
          comuna: filters.comuna,
          region: translateRegion(filters.region),
          exam_type: filters.exam_type,
          status: statusMap[filters.status] || filters.status,
          section_origin: "medical-order",
        },
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const fetchAppointmentsBySearch = async () => {
    setLoading(true);
    try {
      const req = await appointmentService.searchAppointments(search);
      setAppointments(req.data.results);
      setTotalRows(req.data.count);
      setNextRequest(req.data.next.replace("http://", "https://"));
      setPrevRequest(req.data.previous.replace("http://", "https://"));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const handleFilterButton = (): void => {
    fetchAppointments();
  };

  const fetchNewPage = async (action: "next" | "previous"): Promise<void> => {
    setLoading(true);
    try {
      const request = await api.request({
        method: "GET",
        url: action === "next" ? nextRequest : prevRequest,
      });
      setAppointments(request.data.results);
      setTotalRows(request.data.count);
      setNextRequest(request.data.next.replace("http://", "https://"));
      setPrevRequest(request.data.previous.replace("http://", "https://"));
    } catch (err) {
      setError(err);
    }
    setLoading(false);
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    const action = currentPage > newPage ? "previous" : "next";
    fetchNewPage(action);
    setCurrentPage(newPage);
  };

  const createCommuneFilter = (): JSX.Element => {
    const regiones = Object.keys(regionComuna);
    let comunas = regionComuna[filters?.region === "Todas" ? regiones[0] : filters?.region];
    if (filters?.country === "México") {
      comunas = ciudadColonia[filters?.region];
    }
    const label = filters?.country === "Chile" ? "Comuna" : "Colonia";
    return (
      <div className="my-2 mx-4">
        <FormControl margin="normal">
          <InputLabel>{label}</InputLabel>
          <Select
            className="w-52 mb-4"
            value={filters?.comuna}
            disabled={filters?.region === "Todas"}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_COMMUNE",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            <MenuItem value="Todas">Todas</MenuItem>
            {comunas?.map((commune: string) => (
              <MenuItem
                value={commune}
                key={commune}
              >
                {commune}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const createCountryFilter = (): JSX.Element => {
    return (
      <div className="my-2 mx-4">
        <FormControl margin="normal">
          <InputLabel>País</InputLabel>
          <Select
            className="w-52 mb-4"
            value={filters?.country}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_COUNTRY",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            {COUNTRIES.map((country: string) => (
              <MenuItem
                value={country}
                key={country}
              >
                {country}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const createRegionFilter = (): JSX.Element => {
    let regions = [];
    if (filters?.country === "Chile") {
      regions = Object.keys(regionComuna).slice(0, -1); // Remove default key
    } else if (filters?.country === "México") {
      regions = ["CDMX"];
    }
    const label = filters?.country === "Chile" ? "Región" : "Ciudad";
    return (
      <div className="my-2 mx-4">
        <FormControl margin="normal">
          <InputLabel>{label}</InputLabel>
          <Select
            className="w-64 mb-4"
            value={filters?.region}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_REGION",
                payload: e.target.value as string,
              });
            }}
            MenuProps={locationSelectProps}
          >
            <MenuItem value="Todas">Todas</MenuItem>
            {regions.map((region: string) => (
              <MenuItem
                value={region}
                key={region}
              >
                {translateRegion(region)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const createStatusFilter = (): JSX.Element => {
    return (
      <div className="my-2 mx-4">
        <FormControl margin="normal">
          <InputLabel>Status</InputLabel>
          <Select
            className="w-38"
            value={filters?.status}
            onChange={(e) => {
              filtersDispatch({
                type: "UPDATE_STATUS",
                payload: e.target.value as string,
              });
            }}
          >
            <MenuItem value="Todos">Todos</MenuItem>
            <MenuItem value="Confirmado">Confirmado</MenuItem>
            <MenuItem value="Sin confirmar">Sin confirmar</MenuItem>
          </Select>
        </FormControl>
      </div>
    );
  };

  useEffect((): void => {
    if (!search) {
      fetchAppointments();
    }
  }, []);

  useEffect(() => {
    if (search) {
      fetchAppointmentsBySearch();
    }
  }, [search]);

  return (
    <PrivateRoute>
      <LoadingError
        error={error}
        loading={loading}
      />
      {!loading && isEmpty(error) && (
        <>
          <div className="max-w-7xl px-12 py-8 mx-auto">
            <div className="flex flex-col items-center">
              <h1>
                Estado de <span className="italic">Appointments de Órdenes médicas</span>
              </h1>
              <div className="flex items-center justify-center flex-wrap">
                <div className="my-2 mx-4">
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale="es"
                  >
                    <KeyboardDatePicker
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha inicio"
                      value={filters?.initDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_INITDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                <div className="my-2 mx-4">
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    locale="es"
                  >
                    <KeyboardDatePicker
                      disableToolbar
                      variant="inline"
                      format="LL"
                      margin="normal"
                      label="Fecha término"
                      value={filters?.finishDate}
                      autoOk
                      onChange={(date: moment.Moment) => {
                        filtersDispatch({
                          type: "UPDATE_FINISHDATE",
                          payload: date,
                        });
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </div>
                {createStatusFilter()}
              </div>
              <div className="flex items-center justify-center flex-wrap">
                {createCountryFilter()}
                {createRegionFilter()}
                {createCommuneFilter()}
                <div className="my-2 mx-4">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={handleFilterButton}
                    className="max-h-10 border-primary text-primary"
                  >
                    Filtrar
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col justify-end items-start m-8">
            <UploadMedicalOrderRequest />
          </div>
          <MedicalOrderGrid
            appointments={appointments}
            totalRows={totalRows}
            currentPage={currentPage}
            handleChangePage={handleChangePage}
          />
        </>
      )}
    </PrivateRoute>
  );
};

export default IndexPage;
